import pusher from "./base";
import { PusherMock } from "pusher-js-mock";
export var NEW_NOTIFICATION_PUSHER_EVENT = "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";
export var subscribeToPusherNotificationChannel = function (token, subscriberId, callback) {
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth/notification-channel", { method: "POST" });
        var channel = pusherInstance.subscribe("private-subscribers.".concat(subscriberId));
        channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
            callback(notification);
        });
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        var channel = pusher(token).subscribe("private-subscribers.".concat(subscriberId));
        channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
            callback(notification);
        });
        return channel;
    }
};
