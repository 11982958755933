var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAppContent, selectStyles, selectTimezone, selectDetectTimezoneAbbr, selectDsTimezoneAbbreviation, selectMedia, selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import classNames from "classnames";
import { OpenLoginModalButton } from "../../ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
import "./Hero.scss";
import { DownOutlined } from "@ant-design/icons";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { getFormattedFirstAvailableDate } from "../../../utils/get-live-date";
import HeroVideo from "./HeroVideo/HeroVideo";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { HeroDynamicStyles } from "./HeroDynamicStyles";
import moment from "moment";
import { HeroEpisodeCard } from "./HeroEpisodeCard/HeroEpisodeCard";
import Typography from "../../ui/Typography/Typography";
import { useEpisodesToShowInHomepage } from "../../../hooks/useEpisodesToShowInHomepage";
var Hero = function (_a) {
    var _b, _c, _d;
    var _e, _f, _g;
    var children = _a.children, centered = _a.centered, animation = _a.animation, props = __rest(_a, ["children", "centered", "animation"]);
    var windowSize = useBreakpoint().windowSize;
    var contents = useSelector(selectAppContent);
    var locale = useSelector(selectLocaleLanguage);
    var timezone = useSelector(selectTimezone);
    var media = useSelector(selectMedia);
    var styles = useSelector(selectStyles);
    var heroVideoMobileBreakpoint = useSelector(selectStyles).heroVideoMobileBreakpoint;
    var timezoneAbbreviation = useSelector(selectDetectTimezoneAbbr);
    var dsTimezoneAbbreviation = useSelector(selectDsTimezoneAbbreviation);
    var episodesToShowInHomepage = useEpisodesToShowInHomepage().episodesToShowInHomepage;
    var showEpisodesCards = styles.hero_show_multiple_episodes;
    var showScrollDownButtonDesktop = styles.hero_height_desktop === "100vh";
    var showScrollDownButtonMobile = styles.hero_height_mobile === "100vh";
    var _h = useState(false), firstLoadOfEpisodes = _h[0], setFirstLoadOfEpisodes = _h[1];
    useEffect(function () {
        if ((episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage.length) > 0)
            setFirstLoadOfEpisodes(true);
    }, [episodesToShowInHomepage]);
    var _j = useSelector(selectStyles), hero_animation_initial_timeout = _j.hero_animation_initial_timeout, hero_animation_timeout = _j.hero_animation_timeout;
    var animationInitialTimeout = !!hero_animation_initial_timeout ? parseInt(hero_animation_initial_timeout) : 200;
    var animationTimeout = !!hero_animation_timeout ? parseInt(hero_animation_timeout) : 400;
    var getMediaResponsive = function () {
        if (windowSize.width <= (heroVideoMobileBreakpoint || 767)) {
            if (media === null || media === void 0 ? void 0 : media.body_background_image_mobile)
                return media === null || media === void 0 ? void 0 : media.body_background_image_mobile;
        }
        return media === null || media === void 0 ? void 0 : media.body_background_image_desktop;
    };
    var formattedLiveDate = getFormattedFirstAvailableDate({
        liveDatesInUtc: episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage.map(function (episode) { var _a; return (_a = episode === null || episode === void 0 ? void 0 : episode.live_scheduling) === null || _a === void 0 ? void 0 : _a.start_at; }),
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        currentUTCTime: moment.utc().format(),
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_live_time,
        locale: locale
    });
    var formattedVODDate = getFormattedFirstAvailableDate({
        liveDatesInUtc: episodesToShowInHomepage === null || episodesToShowInHomepage === void 0 ? void 0 : episodesToShowInHomepage.map(function (episode) { var _a; return (_a = episode === null || episode === void 0 ? void 0 : episode.vod_scheduling) === null || _a === void 0 ? void 0 : _a.start_at; }),
        timezone: timezone,
        timeFormatType: contents.time_format,
        dateFormatType: contents.date_format,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        currentUTCTime: moment.utc().format(),
        hideTime: styles === null || styles === void 0 ? void 0 : styles.hero_hide_vod_time,
        locale: locale
    });
    var scrollToEndOfHeroBlock = function () {
        var agendaElement = document.getElementById("agenda");
        if (agendaElement) {
            agendaElement === null || agendaElement === void 0 ? void 0 : agendaElement.scrollIntoView({ behavior: "smooth" });
        }
        else {
            var arrowDown = document.querySelector(".hero-wrapper .hero-scroll-down-button");
            if (arrowDown)
                arrowDown === null || arrowDown === void 0 ? void 0 : arrowDown.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (_jsxs("div", __assign({ className: classNames("hero-wrapper", (_b = {}, _b["animation-ready"] = firstLoadOfEpisodes, _b)) }, { children: [animation && _jsx(HeroDynamicStyles, { animationInitialTimeout: animationInitialTimeout, animationTimeout: animationTimeout }), _jsx("div", __assign({ className: classNames("hero-container", (_c = {
                        isVideo: media && windowSize.width && ((_e = getMediaResponsive()) === null || _e === void 0 ? void 0 : _e.type) === "video",
                        isImage: media && windowSize.width && ((_f = getMediaResponsive()) === null || _f === void 0 ? void 0 : _f.type) !== "video"
                    },
                    _c["hero-animated-background"] = animation,
                    _c)) }, { children: media && windowSize.width && ((_g = getMediaResponsive()) === null || _g === void 0 ? void 0 : _g.type) === "video" && _jsx(HeroVideo, { source: getMediaResponsive().url }) })), _jsx(BlockWrapper, __assign({ noGutter: props.noGutter }, { children: _jsxs("div", __assign({ "data-testid": "block-hero-contents", className: classNames("hero-contents", (_d = {}, _d["centered"] = showEpisodesCards, _d)) }, { children: [_jsx(Typography.Title, __assign({ level: 1, className: "hero-contents__title hero-animated-element" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents === null || contents === void 0 ? void 0 : contents.event_title, tag: "div" }) })), _jsx(Typography.Title, __assign({ level: 2, className: "hero-contents__subtitle hero-animated-element" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.sub_title, tag: "div" }) })), showEpisodesCards ? (_jsx(_Fragment, { children: episodesToShowInHomepage.map(function (episode) {
                                return _jsx(HeroEpisodeCard, { className: "hero-animated-element", episode: episode }, episode.id);
                            }) })) : (_jsxs(_Fragment, { children: [formattedLiveDate && (_jsxs(Typography.Title, __assign({ "data-testid": "single-live-date", level: 3, className: "hero-contents__live-date hero-animated-element" }, { children: [!!contents.live_datetime_title && (_jsxs(_Fragment, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.live_datetime_title, tag: styles.date_on_new_line ? "div" : "span" }), " "] })), formattedLiveDate] }))), formattedVODDate && (_jsxs(Typography.Title, __assign({ "data-testid": "single-vod-date", level: 3, className: "hero-contents__vod-date hero-animated-element" }, { children: [!!contents.vod_datetime_title && (_jsxs(_Fragment, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.vod_datetime_title, tag: styles.date_on_new_line ? "div" : "span" }), " "] })), formattedVODDate] })))] })), _jsx("div", __assign({ className: "hero-contents__cta-container hero-animated-element" }, { children: _jsx(OpenLoginModalButton, { className: "hero-login-button", size: "large" }) }))] })) })), _jsxs("div", __assign({ className: classNames("hero-scroll-down-button", {
                    "hero-scroll-down-button--mobile": showScrollDownButtonMobile,
                    "hero-scroll-down-button--desktop": showScrollDownButtonDesktop
                }), "aria-label": "Scroll down", tabIndex: 0, onClick: scrollToEndOfHeroBlock, onKeyDown: scrollToEndOfHeroBlock }, { children: [_jsx(DownOutlined, { className: "hero-scroll-down-icon hero-scroll-down-icon--1" }), _jsx(DownOutlined, { className: "hero-scroll-down-icon hero-scroll-down-icon--2" }), _jsx(DownOutlined, { className: "hero-scroll-down-icon hero-scroll-down-icon--3" })] }))] })));
};
export default Hero;
