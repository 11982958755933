import { selectChatVendor } from "@adm-media/adam-store-plugin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export var useCookieConsent = function () {
    var chatVendor = useSelector(selectChatVendor);
    var _a = useState(false), isCookieScriptSet = _a[0], setIsCookieScriptSet = _a[1];
    var _b = useState(false), isBannerLoaded = _b[0], setIsBannerLoaded = _b[1];
    var _c = useState({
        necessary: true,
        functional: false,
        analytics: false,
        performance: false,
        advertisement: false
    }), cookiesAccepted = _c[0], setCookiesAccepted = _c[1];
    var _d = useState(false), isCookieConsentUpdated = _d[0], setIsCookieConsentUpdated = _d[1];
    useEffect(function () {
        var handleCookieBannerConsentUpdate = function () {
            setIsCookieConsentUpdated(true);
        };
        var handleCookieBannerLoadEvent = function (eventData) {
            var eventDetails = eventData === null || eventData === void 0 ? void 0 : eventData.detail;
            document.addEventListener("cookieyes_consent_update", handleCookieBannerConsentUpdate);
            setIsBannerLoaded(true);
            setCookiesAccepted(eventDetails.categories);
        };
        if (chatVendor === 1 && isCookieScriptSet === false) {
            var script = document.createElement("script");
            script.src = "https://cdn-cookieyes.com/client_data/b04ebf7448e09fcd952fb196/script.js";
            script.id = "cookieyes";
            script.type = "text/javascript";
            document.addEventListener("cookieyes_banner_load", handleCookieBannerLoadEvent);
            document.head.appendChild(script);
            setIsCookieScriptSet(true);
        }
    }, [chatVendor, isCookieScriptSet]);
    useEffect(function () {
        if (isBannerLoaded === false || isCookieConsentUpdated === false)
            return;
        if (window.getCkyConsent === undefined)
            return;
        var newConsent = window.getCkyConsent();
        var newCategories = newConsent.categories;
        if (newCategories.functional === false && cookiesAccepted.functional === true) {
            console.log(">>>>>>>>>>>> WINDOW RELOAD");
            // window.location.reload();
        }
        setCookiesAccepted(newCategories);
        setIsCookieConsentUpdated(false);
    }, [isBannerLoaded, isCookieConsentUpdated]);
    return { isBannerLoaded: isBannerLoaded, cookiesAccepted: cookiesAccepted };
};
