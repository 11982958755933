var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { changeEpisodeQAEnableStatus, resetNewQuestion, selectAppContent, selectAppLanguages, selectEpisodes, selectEventId, selectLogin, selectMedia, selectNewQuestion, selectQAndAEpisodesStatus, sendQuestionThunk } from "@adm-media/adam-store-plugin";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
import Typography from "../../ui/Typography/Typography";
import Form from "../../ui/Form/Form";
import SelectLang from "../../ui/Select/SelectLang/SelectLang";
import "./PublicQuestion.scss";
import Button from "../../ui/Buttons/Button";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
import Input from "../../ui/Input/Input";
import Space from "../../ui/Space/Space";
import Select from "../../ui/Select/Select";
import Divider from "../../ui/Divider/Divider";
import { Flex } from "antd";
import { subscribeToPublicQAPusherChannel } from "../../../pusher/subscribe-to-public-qa-channel";
var Title = Typography.Title;
export var PublicQuestion = function (_a) {
    var _b;
    var animation = _a.animation, className = _a.className, noNavbarElements = _a.noNavbarElements, props = __rest(_a, ["animation", "className", "noNavbarElements"]);
    var contents = useSelector(selectAppContent);
    var form = Form.useForm()[0];
    var eventId = useSelector(selectEventId);
    var _c = useSelector(selectMedia), header_logo_desktop = _c.header_logo_desktop, header_logo_mobile = _c.header_logo_mobile;
    var baseClass = "question-block";
    var languages = useSelector(selectAppLanguages);
    var qaEpisodesStatus = useSelector(selectQAndAEpisodesStatus);
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var notification = useNotification().notification;
    var dispatch = useAppDispatch();
    var loadingNewQuestion = useSelector(selectNewQuestion).loading;
    var episodes = useSelector(selectEpisodes);
    var episodesWithPublicQandA = episodes.filter(function (episode) { return !!episode.is_public_qaa_open; });
    var _d = useState(null), selectedEpisodeId = _d[0], setSelectedEpisodeId = _d[1];
    var _e = useState(undefined), pusherPublicQAChannel = _e[0], setPusherPublicQAChannel = _e[1];
    var loadingLogin = useSelector(selectLogin).loading;
    useEffect(function () {
        var episodesWithPublicQandA = episodes === null || episodes === void 0 ? void 0 : episodes.filter(function (episode) { return !!(episode === null || episode === void 0 ? void 0 : episode.is_public_qaa_open); });
        var isLoggingIn = loadingLogin === "pending";
        if (eventId && !pusherPublicQAChannel && episodesWithPublicQandA.length > 0 && !isLoggingIn) {
            setPusherPublicQAChannel(subscribeToPublicQAPusherChannel({
                config: { appId: eventId },
                qaStatusChangeCallback: function (status) {
                    dispatch(changeEpisodeQAEnableStatus(status.actions));
                }
            }));
        }
    }, [eventId, pusherPublicQAChannel, episodes, loadingLogin]);
    useEffect(function () {
        return function () {
            if (pusherPublicQAChannel) {
                try {
                    pusherPublicQAChannel.unsubscribe();
                }
                catch (_a) { }
            }
        };
    }, [pusherPublicQAChannel]);
    useEffect(function () {
        var episodesWithPublicQandA = episodes.filter(function (episode) { return !!episode.is_public_qaa_open; });
        if (episodesWithPublicQandA.length === 1)
            setSelectedEpisodeId(episodesWithPublicQandA[0].id);
    }, [episodes]);
    var handleSubmit = function (values) {
        var localStorageSubscriberIdKey = "".concat(eventId, "-public-qna-subscriber-id");
        var currentSubscriberId = localStorage.getItem(localStorageSubscriberIdKey);
        var parsedCurrentSubscriberId = null;
        try {
            parsedCurrentSubscriberId = currentSubscriberId ? parseInt(currentSubscriberId) : null;
        }
        catch (_a) { }
        var sendQuestionObject = parsedCurrentSubscriberId !== null && Number.isNaN(parsedCurrentSubscriberId) === false
            ? {
                episode_id: selectedEpisodeId,
                original_content: values.question,
                subscriber_id: parsedCurrentSubscriberId
            }
            : {
                episode_id: selectedEpisodeId,
                original_content: values.question
            };
        dispatch(sendQuestionThunk(sendQuestionObject))
            .unwrap()
            .catch(function () {
            notification.showGenericError();
            dispatch(resetNewQuestion());
        })
            .then(function (data) {
            if (typeof (data === null || data === void 0 ? void 0 : data.subscriber_id) === "number") {
                localStorage.setItem(localStorageSubscriberIdKey, data === null || data === void 0 ? void 0 : data.subscriber_id);
            }
        });
    };
    var handleReset = function () {
        form.resetFields();
        dispatch(resetNewQuestion());
    };
    useEffect(function () {
        return function () {
            handleReset();
        };
    }, []);
    var getIsEnabledPublicQA = function () {
        if (!selectedEpisodeId)
            return false;
        var selectedEpisode = episodes.find(function (episode) { return episode.id === selectedEpisodeId; });
        if (!selectedEpisode)
            return false;
        else if (selectedEpisode && typeof qaEpisodesStatus[selectedEpisode.id] === "boolean") {
            return qaEpisodesStatus[selectedEpisode.id];
        }
        else
            return !!selectedEpisode.qaa_enabled;
    };
    return (_jsx(BlockWrapper, __assign({}, props, { className: classNames(baseClass, (_b = {}, _b["".concat(className)] = !!className, _b)), "data-testid": "block-question" }, { children: _jsxs("div", __assign({ ref: contentRef, className: classNames("".concat(baseClass, "__content"), contentAnimationClass) }, { children: [!!!noNavbarElements && (_jsxs(Flex, __assign({ align: "center", justify: "space-between" }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--desktop") }, { children: _jsx("img", { src: header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url, alt: "Logo" }) })), _jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--mobile") }, { children: _jsx("img", { src: header_logo_mobile === null || header_logo_mobile === void 0 ? void 0 : header_logo_mobile.url, alt: "Logo" }) })), languages.length > 1 && _jsx(SelectLang, {})] }))), _jsx(Title, __assign({ className: "".concat(baseClass, "__event-title"), level: props.titleLevel || 1 }, { children: contents.event_title })), contents.qna_title && (_jsx(Title, __assign({ className: "".concat(baseClass, "__qna-title"), "data-testid": "public-qanda-title", level: props.titleLevel || 2 }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.qna_title, tag: "span" }) }))), (episodesWithPublicQandA === null || episodesWithPublicQandA === void 0 ? void 0 : episodesWithPublicQandA.length) > 1 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Form, __assign({ layout: "vertical" }, { children: _jsx(Form.Item, __assign({ label: contents.public_qna_select_episode_label, required: true }, { children: _jsx(Select, { options: episodesWithPublicQandA.map(function (episode) {
                                        return { label: episode.title, value: episode.id };
                                    }), "data-testid": "public-qanda-select-episode", style: { width: 260 }, value: selectedEpisodeId, onChange: function (value) { return setSelectedEpisodeId(value); } }) })) })), _jsx(Divider, {})] })), _jsxs("div", { children: [!(loadingNewQuestion === "succeeded") && (_jsxs(Form, __assign({ form: form, layout: "vertical", onFinish: handleSubmit, "data-testid": "public-qanda-question-form" }, { children: [_jsx(Form.Item, __assign({ name: "question", label: contents.qna_form_textarea_label, rules: [{ required: true, message: contents.field_required }] }, { children: _jsx(Input.TextArea, { autoSize: { minRows: 4, maxRows: 10 }, placeholder: (contents === null || contents === void 0 ? void 0 : contents.qna_form_textarea_placeholder) || undefined, "data-testid": "public-qanda-textarea", disabled: !getIsEnabledPublicQA() }) })), _jsx(Form.Item, { children: _jsx(Button, __assign({ disabled: !getIsEnabledPublicQA(), type: "primary", htmlType: "submit", "data-testid": "public-qanda-submit-button", className: "".concat(baseClass, "__submit-button"), loading: loadingNewQuestion === "pending" }, { children: (contents === null || contents === void 0 ? void 0 : contents.submit_question) || "Submit" })) })] }))), loadingNewQuestion === "succeeded" && (_jsxs(Space, __assign({ direction: "vertical", "data-testid": "public-qanda-question-submitted" }, { children: [_jsx("span", __assign({ className: "".concat(baseClass, "__success-label") }, { children: (contents === null || contents === void 0 ? void 0 : contents.question_submitted) || "Thank you for submitting the question" })), _jsx(Button, __assign({ "data-testid": "public-qanda-send-another-button", onClick: handleReset }, { children: contents.send_another_question || "Send another question" }))] })))] })] })) })));
};
