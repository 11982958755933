var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectEpisodes, selectPublicConfig, setIsDrawerOpen } from "@adm-media/adam-store-plugin";
import SelectEpisodeCard from "../../components/functional/SelectEpisodeCard/SelectEpisodeCard";
import { useEffect } from "react";
import Row from "../../components/ui/Row/Row";
import Col from "../../components/ui/Col/Col";
import { useSetCurrentEpisode } from "../../hooks/useSetCurrentEpisode";
import { useAppDispatch } from "../../store";
import { EpisodesGroupsTabs } from "../../components/functional/EpisodesGroupsTabs/EpisodesGroupsTabs";
import "./EpisodeProposal.scss";
import { isEpisodesGroups } from "../../utils/isEpisodesGroups";
export var EpisodeProposalPage = function (_a) {
    var playerChannelAction = _a.playerChannelAction;
    var dispatch = useAppDispatch();
    var episodes = useSelector(selectEpisodes);
    var publicConfig = useSelector(selectPublicConfig);
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    var baseClass = "episode-proposal";
    useEffect(function () {
        if (episodes.length === 1)
            setCurrentEpisode({ episodeId: episodes[0].id });
    }, []);
    useEffect(function () {
        window.scrollTo(0, 0);
        dispatch(setIsDrawerOpen(false));
    }, []);
    var availableIds = episodes.map(function (episode) { return episode.id; });
    var renderEpisodesGroups = isEpisodesGroups({ episodesGroups: publicConfig === null || publicConfig === void 0 ? void 0 : publicConfig.episodesGroups, availableEpisodesIds: availableIds });
    var renderEpisodesCards = Array.isArray(episodes) && episodes.length > 1;
    return (_jsx(Row, __assign({ "data-testid": "episode-proposal-container", className: baseClass, justify: "center" }, { children: renderEpisodesCards && (_jsx(Col, __assign({ span: 20, className: "".concat(baseClass, "__available-episodes") }, { children: renderEpisodesGroups ? (_jsx(EpisodesGroupsTabs, { playerChannelAction: playerChannelAction })) : (_jsx(Row, __assign({ gutter: [24, 24], "data-testid": "episodes-selection-cards-container" }, { children: episodes.map(function (episode) {
                    return (_jsx(Col, __assign({ xxl: (episodes === null || episodes === void 0 ? void 0 : episodes.length) === 2 ? 12 : 8, lg: 12, xs: 24, "data-testid": "episode-selection-card", style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: episode.id }); } }, { children: _jsx(SelectEpisodeCard, { episode: episode, playerChannelAction: playerChannelAction }) }), episode.id));
                }) }))) }))) })));
};
