var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import RightNavigation from "../../components/ui/RightNavigation/RightNavigation";
import Row from "../../components/ui/Row/Row";
import Col from "../../components/ui/Col/Col";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Wrapper } from "../../components/ui/Player/Wrapper";
import { useSelector } from "react-redux";
import { resetSubscribeChannelPresenceThunk, selectCurrentEpisode, selectDocumentVisibilityStatus, selectPlayerSource, setIsSourceReady } from "@adm-media/adam-store-plugin";
import { useAppDispatch } from "../../store";
import { LIVE } from "../../types/player";
import "./Watch.scss";
import { EpisodeCountdown } from "../../components/blocks/EpisodeCountdown/EpisodeCountdown";
import moment from "moment";
var DEFAULT_PLAYER_PADDING_RIGHT = 80;
export var WatchPage = function () {
    var _a;
    // always open navbar is active when breakpoint > isTablet
    var _b = useBreakpoint(), isMobile = _b.isMobile, isMiniTablet = _b.isMiniTablet, isTablet = _b.isTablet;
    var navRef = useRef();
    var dispatch = useAppDispatch();
    var documentVisibilityStatus = useSelector(selectDocumentVisibilityStatus);
    var playerSource = useSelector(selectPlayerSource);
    var countdown_closed_at = useSelector(selectCurrentEpisode).countdown_closed_at;
    var closableSidebar = isMobile || isMiniTablet || isTablet;
    var _c = useState(closableSidebar ? 0 : DEFAULT_PLAYER_PADDING_RIGHT), playerMarginRight = _c[0], setPlayerMarginRight = _c[1];
    var onDreawerToggled = function (width) {
        setPlayerMarginRight(closableSidebar ? 0 : width + DEFAULT_PLAYER_PADDING_RIGHT);
    };
    var _d = useState(!!!countdown_closed_at ? false : moment.tz(countdown_closed_at, "utc").isBefore() ? false : true), showCountdown = _d[0], setShowCountdown = _d[1];
    var _e = useState(true), showPlayer = _e[0], setShowPlayer = _e[1];
    useEffect(function () {
        var _a;
        if (documentVisibilityStatus === "hidden" &&
            playerSource.status === LIVE &&
            ((_a = playerSource.channel) === null || _a === void 0 ? void 0 : _a.is_fake_live) &&
            !!!(document === null || document === void 0 ? void 0 : document.fullscreenElement)) {
            setShowPlayer(false);
            dispatch(resetSubscribeChannelPresenceThunk({}));
            dispatch(setIsSourceReady(false));
        }
        else
            setShowPlayer(true);
    }, [documentVisibilityStatus, playerSource.status, playerSource.channel]);
    return (_jsxs("div", __assign({ "data-testid": "watch", className: classNames("watch") }, { children: [_jsx(Row, __assign({ className: "watch__player-container" }, { children: _jsxs(Col, __assign({ span: 24, className: classNames("watch__player-wrapper", (_a = {},
                        _a["watch__player-wrapper--countdown"] = showCountdown,
                        _a)), style: {
                        paddingRight: "".concat(!closableSidebar ? Math.max(playerMarginRight, DEFAULT_PLAYER_PADDING_RIGHT) : 0, "px")
                    } }, { children: [!showCountdown && (showPlayer || documentVisibilityStatus === "visible") && _jsx(Wrapper, {}), showCountdown && _jsx(EpisodeCountdown, { setShowCountdown: setShowCountdown })] })) })), _jsx(RightNavigation, { ref: navRef, onDreawerToggled: onDreawerToggled, isCountdownActive: showCountdown })] })));
};
