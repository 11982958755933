var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Wrapper } from "../../components/ui/Player/Wrapper";
import RightNavigation from "../../components/ui/RightNavigation/RightNavigation";
import "./Iframe.scss";
export var IframePage = function () {
    return (_jsxs("div", __assign({ "data-testid": "iframe", className: "iframe-page" }, { children: [_jsx("div", __assign({ className: "iframe-page__player" }, { children: _jsx(Wrapper, {}) })), _jsx("div", __assign({ className: "iframe-page__navigation" }, { children: _jsx(RightNavigation, {}) }))] })));
};
