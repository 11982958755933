var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import "./sentry";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { getToken } from "./utils/auth";
import getDomain from "./utils/get-domain";
import { App } from "./App";
import { App as AntdApp } from "antd";
import StyleWrapper from "./StyleWrapper";
import { setLocale, setAppDomain, stickToken } from "@adm-media/adam-store-plugin";
import "./styles/index.scss";
import "animate.css";
store.dispatch(setAppDomain(getDomain()));
var ln = (_a = new URLSearchParams(window.location.search)) === null || _a === void 0 ? void 0 : _a.get("ln");
if (ln)
    store.dispatch(setLocale(ln));
var token = getToken();
if (token)
    store.dispatch(stickToken(token));
var container = document.getElementById("root");
var root = createRoot(container);
root.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(StyleWrapper, { children: _jsx(BrowserRouter, { children: _jsx(AntdApp, { children: _jsx(App, {}) }) }) }) })));
