var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectAppContent, selectCurrentEpisode } from "@adm-media/adam-store-plugin";
import Row from "../../components/ui/Row/Row";
import Col from "../../components/ui/Col/Col";
import Button from "../../components/ui/Buttons/Button";
import { SanitizeHtml } from "../../components/ui/SanitizeHtml/SanitizeHtml";
import Divider from "../../components/ui/Divider/Divider";
import "./WaitingRoom.scss";
export var WaitingRoomPage = function () {
    var _a, _b;
    var _c = useState(true), isPlaying = _c[0], setIsPlaying = _c[1];
    var waitingRoom = useSelector(selectCurrentEpisode).waitingRoom;
    var audioRef = useRef();
    var contents = useSelector(selectAppContent);
    useEffect(function () {
        return function () {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);
    var handleToggleAudio = function () {
        var _a, _b, _c;
        if ((_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.paused) {
            (_b = audioRef.current) === null || _b === void 0 ? void 0 : _b.play();
            setIsPlaying(true);
        }
        else {
            (_c = audioRef.current) === null || _c === void 0 ? void 0 : _c.pause();
            setIsPlaying(false);
        }
    };
    return (_jsx(Row, __assign({ "data-testid": "waiting-room", className: "waiting-room" }, { children: _jsxs(Col, __assign({ span: 24 }, { children: [(waitingRoom === null || waitingRoom === void 0 ? void 0 : waitingRoom.jingle_media) && (_jsx("audio", __assign({ loop: true, autoPlay: true, ref: audioRef }, { children: _jsx("source", { src: (_a = waitingRoom === null || waitingRoom === void 0 ? void 0 : waitingRoom.jingle_media) === null || _a === void 0 ? void 0 : _a.url, type: (_b = waitingRoom === null || waitingRoom === void 0 ? void 0 : waitingRoom.jingle_media) === null || _b === void 0 ? void 0 : _b.mime }) }))), _jsx(SanitizeHtml, { stringToSanitize: contents.waiting_room_popup_content, tag: "div", className: "waiting-room__message" }), _jsx(Divider, {}), _jsx(Row, __assign({ className: "waiting-room__footer", justify: "center" }, { children: (waitingRoom === null || waitingRoom === void 0 ? void 0 : waitingRoom.jingle_media) && (_jsx(Button, __assign({ className: "toggle-audio-button", type: "primary", onClick: handleToggleAudio }, { children: isPlaying ? contents.waiting_room_popup_pause_button_text : contents.waiting_room_popup_play_button_text }))) }))] })) })));
};
