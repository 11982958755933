var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { setIsWaitingRoomOpen, fetchEpisodesThunk, fetchDetectThunk, getMeThunk, loginUserThunk, logoutUserThunk, selectEventId, selectMaintenance, selectUser, selectSelectedEpisodeId, selectIsWaitingRoomOpen, selectAreEpisodesLoading, selectRegisteredSelectEpisode, selectVirtual, selectLoadingDetect, setEpisodes, selectLogin, selectIsLoadingLanguage, selectPublishedApp, selectLocaleLanguage, selectAppContent, selectLogout, selectMe, setLoadingDetect, displayModal, resetUserActionState, UserStateAction, selectLocalStorageEpisodeKey, changePlayerSource, changeEpisodeQAEnableStatus, setIsDrawerOpen, selectChatVendor, selectChatLicense, selectUnsubscribeSubscriber, fetchPreviewThunk, addNotification, selectHasNotifications } from "@adm-media/adam-store-plugin";
import { PageLayout } from "./components/wrappers/PageLayout/PageLayout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "./utils/obj-check";
import { message } from "antd";
import { subscribeToPusherChannel } from "./pusher/subscribe-to-pusher-channel";
import { useSetCurrentEpisode } from "./hooks/useSetCurrentEpisode";
import { Spin } from "./components/ui/Spin/Spin";
import { VW_MODAL } from "./components/modals";
import { LocaleChangeOmitCall, useChangeAppLocale } from "./hooks/useChangeAppLocale";
import { useInitializeGtm } from "./hooks/useInitializeGtm";
import { useShowInitialModal } from "./hooks/useInitialmodal";
import { useLoadFonts } from "./hooks/useLoadFonts";
import { useAppDispatch } from "./store";
import { useSetFavicon } from "./hooks/useSetFavicon";
import { useSetNetworkStatus } from "./hooks/useSetNetworkStatus";
import { LiveChatWidget } from "@livechat/widget-react";
import { ErrorPage, EpisodeProposalPage, VideotestPage, PublicQandAPage, HomePage, MaintenancePage, WaitingRoomPage, WatchPage, ChangePasswordPage, ChairmanPage, ModeratorPage, ResetPasswordPage, UnsubscribePage, Paths } from "./pages";
import ModalsConnect from "./components/modals/ModalsProvider";
import { useHandleErrors } from "./hooks/useHandleErrors";
import { EpisodeSelectedWrapper } from "./components/wrappers/EpisodeSelectedWrapper/EpisodeSelectedWrapper";
import { useSetWebsiteTitle } from "./hooks/useSetWebsiteTitle";
import { useSetDocumentVisibilityStatus } from "./hooks/useSetDocumentVisibilityStatus";
import { useLogoutIfOffline } from "./hooks/useLogoutIfOffline";
import { useWriteOnDOM } from "./hooks/useWriteOnDOM";
import { subscribeToPusherNotificationChannel } from "./pusher/subscribe-to-notification-channel";
import { useCookieConsent } from "./hooks/useCookieConsent";
export var App = function () {
    var dispatch = useAppDispatch();
    var _a = useLocation(), pathname = _a.pathname, search = _a.search;
    var eventId = useSelector(selectEventId);
    var localStorageId = useSelector(selectLocalStorageEpisodeKey);
    var virtualWorld = useSelector(selectVirtual);
    var maintenance = useSelector(selectMaintenance);
    var isAppPublished = useSelector(selectPublishedApp);
    var currentLocale = useSelector(selectLocaleLanguage);
    var loadingDetect = useSelector(selectLoadingDetect);
    var chatVendor = useSelector(selectChatVendor);
    var chatLicense = useSelector(selectChatLicense);
    var isWaitingRoomOpen = useSelector(selectIsWaitingRoomOpen);
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var loadingEpisodes = useSelector(selectAreEpisodesLoading);
    var eventHasNotifications = useSelector(selectHasNotifications);
    var loadingLogin = useSelector(selectLogin).loading;
    var loadingLogout = useSelector(selectLogout).loading;
    var loadingMe = useSelector(selectMe).loading;
    var loadingUnsubscribeSubscriber = useSelector(selectUnsubscribeSubscriber).loading;
    var contents = useSelector(selectAppContent);
    var _b = useSelector(selectUser), token = _b.token, identity = _b.identity, isModerator = _b.isModerator, isChairman = _b.isChairman;
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var _c = useState(), pusherChannel = _c[0], setPusherChannel = _c[1];
    var _d = useState(), pusherNotificationChannel = _d[0], setPusherNotificationChannel = _d[1];
    var _e = useState(null), playerChannelActions = _e[0], setPlayerChannelActions = _e[1];
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    var isIframeRoute = pathname === "/iframe";
    var iam = new URLSearchParams(search).get("iam");
    var isLoggedIn = !!(identity === null || identity === void 0 ? void 0 : identity.email) && !!token;
    var mustChangePassword = typeof (identity === null || identity === void 0 ? void 0 : identity.change_password_required) === "number" && (identity === null || identity === void 0 ? void 0 : identity.change_password_required) !== 0;
    var shouldRenderWaitingRoom = selectedEpisodeId && isWaitingRoomOpen;
    var shouldRenderVW = isLoggedIn && !virtualWorld.active && virtualWorld.has && !isModerator && !isChairman;
    var isLoadingRegisterSelectedEpisode = useSelector(selectRegisteredSelectEpisode).isLoading;
    var isLoadingLanguage = useSelector(selectIsLoadingLanguage);
    var isLoadingDetect = loadingDetect === "pending" || loadingDetect === "uninitialized";
    var isLoadingEpisodes = loadingEpisodes === "pending" || loadingEpisodes === "uninitialized";
    var isLoadingLogout = loadingLogout === "pending";
    var isLoadingMe = loadingMe === "pending";
    var isLoadingUnsubscribeSubscriber = loadingUnsubscribeSubscriber === "pending";
    var isLoading = isLoadingRegisterSelectedEpisode ||
        isLoadingLanguage ||
        isLoadingDetect ||
        isLoadingEpisodes ||
        isLoadingLogout ||
        isLoadingMe ||
        isLoadingUnsubscribeSubscriber;
    var pathElements = pathname === null || pathname === void 0 ? void 0 : pathname.split("/");
    var isUnsubscribeRoute = (pathElements === null || pathElements === void 0 ? void 0 : pathElements[1]) === "unsubscribe" && typeof (pathElements === null || pathElements === void 0 ? void 0 : pathElements[2]) === "string";
    var goToWatchPlayerRoute = isLoggedIn &&
        !mustChangePassword &&
        selectedEpisodeId &&
        pathname !== Paths.watchPlayer &&
        !isModerator &&
        !isChairman &&
        !isUnsubscribeRoute;
    var goToEpisodeProposalRoute = isLoggedIn && !mustChangePassword && !selectedEpisodeId && pathname !== Paths.watch && !isModerator && !isChairman && !isUnsubscribeRoute;
    var goToChairmanRoute = isChairman && !isIframeRoute && pathname !== Paths.chairman && !mustChangePassword && !isUnsubscribeRoute;
    var goToModeratorRoute = isModerator && !isIframeRoute && pathname !== Paths.moderator && !mustChangePassword && !isUnsubscribeRoute;
    var goToForcedPasswordChange = mustChangePassword && pathname !== Paths.changePassword && !isUnsubscribeRoute;
    useLogoutIfOffline();
    useSetDocumentVisibilityStatus();
    useSetWebsiteTitle();
    useInitializeGtm();
    useLoadFonts();
    useShowInitialModal();
    useSetFavicon();
    useSetNetworkStatus();
    useHandleErrors();
    useWriteOnDOM();
    var _f = useCookieConsent(), isBannerLoaded = _f.isBannerLoaded, cookiesAccepted = _f.cookiesAccepted;
    useEffect(function () {
        console.log("AAAAAAAAAAA IS BANNER LOADED: ", isBannerLoaded);
    }, [isBannerLoaded]);
    useEffect(function () {
        console.log("AAAAAAAAAAAA COOKES ACCEPTED: ", cookiesAccepted);
    }, [cookiesAccepted]);
    useEffect(function () {
        var _a;
        if (loadingDetect === "uninitialized") {
            var preview = (_a = new URLSearchParams(window.location.search)) === null || _a === void 0 ? void 0 : _a.get("preview");
            if (preview) {
                dispatch(fetchPreviewThunk(preview));
            }
            else
                dispatch(fetchDetectThunk());
        }
        else if (loadingDetect === "succeeded") {
            if (token) {
                dispatch(getMeThunk())
                    .unwrap()
                    .then(function (payload) {
                    if (!!(payload === null || payload === void 0 ? void 0 : payload.lang_id) && (payload === null || payload === void 0 ? void 0 : payload.lang_id) !== currentLocale) {
                        changeAppLocale({ newLocale: payload.lang_id, callsToSkip: [LocaleChangeOmitCall.episodes] });
                    }
                })
                    .catch(function () { });
            }
            else {
                dispatch(fetchEpisodesThunk());
            }
            dispatch(setLoadingDetect("idle"));
        }
    }, [loadingDetect, token]);
    /** Direct login (iam) */
    useEffect(function () {
        if (!Boolean(eventId))
            return;
        if (!isLoggedIn && iam) {
            dispatch(loginUserThunk({ email: iam, force: true }))
                .unwrap()
                .then(function (payload) {
                var _a, _b, _c;
                if (!!((_a = payload === null || payload === void 0 ? void 0 : payload.me) === null || _a === void 0 ? void 0 : _a.lang_id) && ((_b = payload === null || payload === void 0 ? void 0 : payload.me) === null || _b === void 0 ? void 0 : _b.lang_id) !== currentLocale) {
                    changeAppLocale({ newLocale: ((_c = payload === null || payload === void 0 ? void 0 : payload.me) === null || _c === void 0 ? void 0 : _c.lang_id) || "en", callsToSkip: [LocaleChangeOmitCall.episodes] });
                }
            })
                .catch(function () {
                message.error(contents.waiting_room_accept_message || "An error has occurred");
            });
        }
    }, [eventId]);
    useEffect(function () {
        var _a;
        var episodeIdFromStorage = eventId ? localStorage.getItem(localStorageId) : null;
        if (loadingLogin === "succeeded" || (loadingMe === "succeeded" && token)) {
            var episodesFromIdentity = Array.isArray(identity === null || identity === void 0 ? void 0 : identity.episodes)
                ? identity === null || identity === void 0 ? void 0 : identity.episodes
                : Object.values((identity === null || identity === void 0 ? void 0 : identity.episodes) || {});
            dispatch(setEpisodes(episodesFromIdentity));
            if (loadingLogin === "succeeded")
                dispatch(resetUserActionState(UserStateAction.login));
            if (loadingMe === "succeeded")
                dispatch(resetUserActionState(UserStateAction.me));
            if (identity.is_chairman || identity.is_moderator)
                return;
            if (token && episodesFromIdentity.length === 1 && !selectedEpisodeId) {
                setCurrentEpisode({ episodeId: (_a = episodesFromIdentity === null || episodesFromIdentity === void 0 ? void 0 : episodesFromIdentity[0]) === null || _a === void 0 ? void 0 : _a.id });
            }
            else if (token && episodesFromIdentity.length > 1 && episodeIdFromStorage && !selectedEpisodeId) {
                var episodeId_1 = parseInt(episodeIdFromStorage);
                if (!Number.isNaN(episodeId_1)) {
                    var thereIsEpisode = episodesFromIdentity.find(function (episode) { return episode.id === episodeId_1; });
                    if (thereIsEpisode)
                        setCurrentEpisode({ episodeId: episodeId_1 });
                }
            }
        }
        else if (!token && selectedEpisodeId) {
            setCurrentEpisode({ episodeId: null });
        }
    }, [loadingLogin, loadingMe, token, identity]);
    useEffect(function () {
        if (shouldRenderVW)
            dispatch(displayModal({ name: VW_MODAL }));
    }, [shouldRenderVW]);
    useEffect(function () {
        if (!isEmpty(identity) && token && eventId && !pusherChannel) {
            setPusherChannel(subscribeToPusherChannel({
                config: { token: token, appId: eventId, userId: identity.id },
                endSessionCallback: function () { return dispatch(logoutUserThunk()); },
                waitingRoomAcceptedCallback: function () {
                    message.success(contents.waiting_room_accept_message || "You have been acceptepted");
                    dispatch(setIsWaitingRoomOpen(false));
                },
                channelStatusChangeCallback: function (data) {
                    dispatch(changePlayerSource(data));
                    setPlayerChannelActions(data);
                },
                qaStatusChangeCallback: function (status) {
                    dispatch(changeEpisodeQAEnableStatus(status.actions));
                }
            }));
        }
        else if (pusherChannel && !token) {
            try {
                pusherChannel.unsubscribe();
                setPusherChannel(undefined);
                // Fetch episodes after a logout!
                dispatch(fetchEpisodesThunk());
                dispatch(setIsDrawerOpen(false));
            }
            catch (_a) { }
        }
    }, [token, identity, pusherChannel, eventId]);
    useEffect(function () {
        if (!isEmpty(identity) && token && eventId && !pusherNotificationChannel && eventHasNotifications && !isModerator && !isChairman) {
            setPusherNotificationChannel(subscribeToPusherNotificationChannel(token, identity.subscription.code, function (notification) {
                if (eventId === notification.event_code)
                    dispatch(addNotification(notification));
            }));
        }
        else if (pusherNotificationChannel && !token) {
            try {
                pusherNotificationChannel.unsubscribe();
                setPusherNotificationChannel(undefined);
            }
            catch (_a) { }
        }
    }, [token, identity, pusherNotificationChannel, eventId, eventHasNotifications, isModerator, isChairman]);
    useEffect(function () {
        if (playerChannelActions)
            setPlayerChannelActions(null);
    }, [setPlayerChannelActions]);
    if (isAppPublished === false)
        return _jsx(ErrorPage, { content: contents.error_app_not_available || "404" });
    if (maintenance)
        return _jsx(MaintenancePage, {});
    if (goToForcedPasswordChange)
        return _jsx(Navigate, { to: Paths.changePassword, replace: true });
    else if (goToChairmanRoute)
        return _jsx(Navigate, { to: Paths.chairman, replace: true });
    else if (goToModeratorRoute)
        return _jsx(Navigate, { to: Paths.moderator });
    else if (goToWatchPlayerRoute)
        return _jsx(Navigate, { to: Paths.watchPlayer });
    else if (goToEpisodeProposalRoute)
        return _jsx(Navigate, { to: Paths.watch });
    return (_jsxs(_Fragment, { children: [_jsx(Spin, { spinning: isLoading, fullPage: true }), _jsx(ModalsConnect, {}), cookiesAccepted.functional && chatVendor === 1 && typeof chatLicense === "string" && !isModerator && !isChairman && (_jsx(LiveChatWidget, { license: chatLicense, customerName: identity.lastname || identity.firstname ? "".concat(identity.lastname, ", ").concat(identity.firstname) : "Utente", customerEmail: identity.email || "Utente", visibility: "minimized" })), _jsxs(Routes, { children: [_jsx(Route, { path: Paths.home, element: !isLoadingMe && !isLoadingDetect && _jsx(HomePage, {}) }), _jsx(Route, { path: "".concat(Paths.unsubscribe, "/:unsubscribeHash"), element: !isLoading && _jsx(UnsubscribePage, {}) }), _jsx(Route, { path: Paths.resetPassword, element: !isLoading && _jsx(ResetPasswordPage, {}) }), _jsx(Route, { path: Paths.changePassword, element: !isLoading && _jsx(ChangePasswordPage, {}) }), isLoggedIn && (_jsxs(_Fragment, { children: [_jsxs(Route, __assign({ element: _jsx(PageLayout, {}) }, { children: [_jsx(Route, { path: Paths.watch, element: _jsx(EpisodeProposalPage, { playerChannelAction: playerChannelActions }) }), _jsx(Route, { path: Paths.watchPlayer, element: _jsx(EpisodeSelectedWrapper, { children: !isLoadingRegisterSelectedEpisode && (shouldRenderWaitingRoom ? _jsx(WaitingRoomPage, {}) : _jsx(WatchPage, {})) }) })] })), _jsx(Route, { path: Paths.moderator, element: isModerator ? _jsx(ModeratorPage, {}) : _jsx(Navigate, { replace: true, to: Paths.watch }) }), _jsx(Route, { path: Paths.chairman, element: isChairman ? _jsx(ChairmanPage, {}) : _jsx(Navigate, { replace: true, to: Paths.watch }) })] })), _jsx(Route, { path: Paths.videotest, element: _jsx(VideotestPage, {}) }), _jsx(Route, { path: Paths.publicQandA, element: _jsx(PublicQandAPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: Paths.home }) })] })] }));
};
