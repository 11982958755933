import pusher from "./base";
import { PusherMock } from "pusher-js-mock";
export var subscribeToPusherIdChannel = function (_a) {
    var _b = _a.config, token = _b.token, appId = _b.appId, channelId = _b.channelId;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "/broadcasting/auth/id-channel", { method: "POST" });
        var channel = pusherInstance.subscribe("presence-".concat(appId, ".123456789"));
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        var channel = pusher(token).subscribe("presence-".concat(appId, ".").concat(channelId));
        return channel;
    }
};
