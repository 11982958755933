import pusher from "./base";
import { PusherMock } from "pusher-js-mock";
export var NEW_QUESTION_TO_MODERATE_PUSHER_EVENT = "App\\Events\\NewQuestionToModerate";
export var NEW_QUESTION_TO_ANSWER_PUSHER_EVENT = "App\\Events\\NewQuestionToAnswer";
export var subscribeToQAQuestionChannel = function (_a, callback) {
    var token = _a.token, appId = _a.appId, episodeId = _a.episodeId, isChairman = _a.isChairman, isModerator = _a.isModerator;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth", { method: "POST" });
        var who = isModerator ? "moderator" : isChairman ? "speaker" : "";
        var channel = pusherInstance.subscribe("private-qaa-".concat(who, "-").concat(appId, ".").concat(episodeId));
        if (isChairman) {
            channel.bind(NEW_QUESTION_TO_ANSWER_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
        }
        if (isModerator) {
            channel.bind(NEW_QUESTION_TO_MODERATE_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
        }
        if (window.Cypress) {
            window.Cypress.pusher = pusherInstance;
        }
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        var who = isModerator ? "moderator" : isChairman ? "speaker" : "";
        var channel = pusher(token).subscribe("private-qaa-".concat(who, "-").concat(appId, ".").concat(episodeId));
        if (isChairman) {
            channel.bind(NEW_QUESTION_TO_ANSWER_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
        }
        if (isModerator) {
            channel.bind(NEW_QUESTION_TO_MODERATE_PUSHER_EVENT, function (_a) {
                var question = _a.question;
                callback(question);
            });
        }
        return channel;
    }
};
