var _a;
import { SpeakerDetailsModal, getSpeakerDetailsModalProps } from "./SpeakerDetailsModal/SpeakerDetailsModal";
import { LoginModal, getLoginModalProps } from "./LoginModal/LoginModal";
import { getLegalModalprops, LegalModal } from "./LegalModal/LegalModal";
import { FAQModal, getFAQModalProps } from "./FAQModal/FAQModal";
import { QAQuestionModal, getQAQuestionModalProps } from "./QAQuestionModal/QAQuestionModal";
import { ConfirmEmailOnRegisterModal, getConfirmEmailOnRegisterModalProps } from "./ConfirmEmailOnRegister/ConfirmEmailOnRegister";
import { EvaluationModal, getEvaluationModalProps } from "./EvaluationModal/EvaluationModal";
import { ThankYouModal, getThankYouModalProps } from "./ThankYouModal/ThankYouModal";
import { QuizModal, getQuizModalProps } from "./QuizModal/QuizModal";
import { ErrorModal, getErrorModalProps } from "./ErrorModal/ErrorModal";
import { InfoModal, getInfoModalProps } from "./InfoModal/InfoModal";
import { VirtualWorldModal, getVirtualWorldModalProps } from "./VirtualWorldModal/VirtualWorldModal";
import { ResetPasswordModal, getResetPasswordModalProps } from "./ResetPasswordModal/ResetPasswordModal";
import { ChangePasswordModal, getChangePasswordModalProps } from "./ChangePasswordModal/ChangePasswordModal";
import { ForceEvaluationFormModal, getForceEvaluationFormModalProps } from "./ForceEvaluationForm/ForceEvaluationForm";
import { ConfirmUnsubscriptionModal, getConfirmUnsubscriptionModalProps } from "./ConfirmUnsubscription/ConfirmUnsubscription";
export var VW_MODAL = "VW_MODAL";
export var FAQ_MODAL = "FAQ_MODAL";
export var INFO_MODAL = "INFO_MODAL";
export var QUIZ_MODAL = "QUIZ_MODAL";
export var LOGIN_MODAL = "LOGIN_MODAL";
export var ERROR_MODAL = "ERROR_MODAL";
export var LEGAL_MODAL = "LEGAL_MODAL";
export var RESET_PASSWORD = "RESET_PASSWORD";
export var THANK_YOU_MODAL = "THANK_YOU_MODAL";
export var EVALUATION_MODAL = "EVALUATION_MODAL";
export var QA_QUESTION_MODAL = "QA_QUESTION_MODAL";
export var SPEAKER_DETAILS_MODAL = "SPEAKER_DETAILS_MODAL";
export var CLOSE_ACTIVE_SESSION_MODAL = "CLOSE_ACTIVE_SESSION_MODAL";
export var CONFIRM_EMAIL_ON_REGISTER_MODAL = "CONFIRM_EMAIL_ON_REGISTER_MODAL";
export var CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL";
export var FORCE_EVALUATION_FORM_MODAL = "FORCE_EVALUATION_FORM_MODAL";
export var CONFIRM_UNSUBSCRIPTION_MODAL = "CONFIRM_UNSUBSCRIPTION_MODAL";
var availableModals = (_a = {},
    _a[VW_MODAL] = { content: VirtualWorldModal, getProps: getVirtualWorldModalProps },
    _a[FAQ_MODAL] = { content: FAQModal, getProps: getFAQModalProps },
    _a[QUIZ_MODAL] = { content: QuizModal, getProps: getQuizModalProps },
    _a[LOGIN_MODAL] = { content: LoginModal, getProps: getLoginModalProps },
    _a[LEGAL_MODAL] = { content: LegalModal, getProps: getLegalModalprops },
    _a[ERROR_MODAL] = { content: ErrorModal, getProps: getErrorModalProps },
    _a[INFO_MODAL] = { content: InfoModal, getProps: getInfoModalProps },
    _a[RESET_PASSWORD] = { content: ResetPasswordModal, getProps: getResetPasswordModalProps },
    _a[THANK_YOU_MODAL] = { content: ThankYouModal, getProps: getThankYouModalProps },
    _a[EVALUATION_MODAL] = { content: EvaluationModal, getProps: getEvaluationModalProps },
    _a[QA_QUESTION_MODAL] = { content: QAQuestionModal, getProps: getQAQuestionModalProps },
    _a[SPEAKER_DETAILS_MODAL] = { content: SpeakerDetailsModal, getProps: getSpeakerDetailsModalProps },
    _a[CONFIRM_EMAIL_ON_REGISTER_MODAL] = { content: ConfirmEmailOnRegisterModal, getProps: getConfirmEmailOnRegisterModalProps },
    _a[CHANGE_PASSWORD_MODAL] = { content: ChangePasswordModal, getProps: getChangePasswordModalProps },
    _a[FORCE_EVALUATION_FORM_MODAL] = { content: ForceEvaluationFormModal, getProps: getForceEvaluationFormModalProps },
    _a[CONFIRM_UNSUBSCRIPTION_MODAL] = { content: ConfirmUnsubscriptionModal, getProps: getConfirmUnsubscriptionModalProps },
    _a);
export default availableModals;
