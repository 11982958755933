var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { displayModal, logoutUserThunk, selectAppContent } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import Button from "../Button";
import { useForcedEvaluationForm } from "../../../../hooks/useForcedEvaluationForm";
import { useAppDispatch } from "../../../../store";
import { FORCE_EVALUATION_FORM_MODAL } from "../../../modals";
export var LogoutButton = function (_a) {
    var className = _a.className;
    var dispatch = useAppDispatch();
    var contents = useSelector(selectAppContent);
    var showEvaluationFormPrompt = useForcedEvaluationForm().showEvaluationFormPrompt;
    var handleLogout = function () {
        if (showEvaluationFormPrompt) {
            dispatch(displayModal({
                name: FORCE_EVALUATION_FORM_MODAL
            }));
        }
        else
            dispatch(logoutUserThunk());
    };
    return (_jsx(Button, __assign({ "data-testid": "logout-button", className: className, type: "primary", onClick: handleLogout }, { children: contents.logout })));
};
