import { momentTimezone } from "../utils/moment";
var utc = momentTimezone.utc;
var getDiffTime = function (date) {
    return momentTimezone().diff(utc(date), "seconds");
};
var localTime = function (time, timezone) {
    var _time = utc(time);
    if (timezone) {
        _time = _time.tz(timezone);
    }
    return _time;
};
function convertPHPToMomentFormat(phpFormat) {
    var stringDate = "";
    var replacements = [
        { d: "DD" },
        { D: "ddd" },
        { j: "D" },
        { l: "dddd" },
        { N: "E" },
        { S: "o" },
        { w: "e" },
        { z: "DDD" },
        { W: "W" },
        { F: "MMMM" },
        { m: "MM" },
        { M: "MMM" },
        { n: "M" },
        { t: "" },
        { L: "" },
        { o: "YYYY" },
        { Y: "YYYY" },
        { y: "YY" },
        { a: "a" },
        { A: "A" },
        { B: "" },
        { g: "h" },
        { G: "H" },
        { h: "hh" },
        { H: "HH" },
        { i: "mm" },
        { s: "ss" },
        { u: "SSS" },
        { e: "zz" },
        { I: "" },
        { O: "" },
        { P: "" },
        { T: "" },
        { Z: "" },
        { c: "" },
        { r: "" },
        { U: "X" },
        { dS: "Do" },
        { ":": ":" },
        { " ": " " },
        { "-": "-" },
        { "/": "/" } // helps only for symbol format
    ];
    if (!phpFormat)
        return "";
    var splittedDate = phpFormat.split(/\b/);
    for (var _i = 0, splittedDate_1 = splittedDate; _i < splittedDate_1.length; _i++) {
        var char = splittedDate_1[_i];
        for (var _a = 0, replacements_1 = replacements; _a < replacements_1.length; _a++) {
            var iterator = replacements_1[_a];
            if (char === Object.keys(iterator)[0]) {
                stringDate += Object.values(iterator)[0];
            }
        }
    }
    return stringDate;
}
export { getDiffTime, localTime, convertPHPToMomentFormat };
